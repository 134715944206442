<template>
  <div style="padding-top: 10px">
    <v-card class="mx-auto" max-width="900">
      <v-card-title>Мой профиль</v-card-title>
      <v-card-subtitle class="pb-0"
        >Логин: {{ userData.email }}</v-card-subtitle
      >
      <v-card-subtitle class="pb-0"
        >Обновите актуальную информацию о Вас.</v-card-subtitle
      >
      <v-card-text>
        <v-container>
          <v-text-field
            v-model.trim="userData.email"
            label="Логин"
            placeholder="Имя для входа"
            outlined
            disabled
            dense
          ></v-text-field>
          <v-text-field
            v-model.trim="userData.name"
            label="Имя"
            placeholder="Ваше имя"
            outlined
            dense
          ></v-text-field>
        </v-container>
        <v-card-actions>
          <v-btn
            class="ma-2"
            outlined
            color="indigo"
            @click.prevent="updatePassword"
            >Изменить пароль</v-btn
          >
          <v-btn class="ma-2" outlined color="green" @click.prevent="updateUser"
            >Обновить профиль</v-btn
          >
        </v-card-actions>
        <v-card class="mx-auto" max-width="900">
          <v-card-title>Персональные данные клиента</v-card-title>

          <v-card-subtitle class="pb-0">
            Укажите Ваши данные для использования онлайн сервисов
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-radio-group
                v-model="userData.partner.orgType"
                :error-messages="orgTypeErrors"
              >
                <v-row>
                  <v-col>
                    <v-radio
                      label="Огранизация/ИП"
                      :value="0"
                      @change="$v.userData.partner.orgType.$touch()"
                    ></v-radio>
                  </v-col>
                  <v-col>
                    <v-radio
                      label="Физическое лицо"
                      :value="1"
                      @change="$v.userData.partner.orgType.$touch()"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
              <div v-if="userData.partner.orgType === 0">
                <v-text-field
                  v-model.trim="userData.partner.name"
                  :counter="99"
                  @input="$v.userData.partner.name.$touch()"
                  @blur="$v.userData.partner.name.$touch()"
                  :error-messages="nameErrors"
                  label="Наименование (ФИО для ИП)"
                ></v-text-field>
                <v-text-field
                  v-model.trim="userData.partner.shortName"
                  :counter="30"
                  @input="$v.userData.partner.shortName.$touch()"
                  @blur="$v.userData.partner.shortName.$touch()"
                  :error-messages="shortNameErrors"
                  label="Сокращенное наименование"
                ></v-text-field>
                <v-text-field
                  v-model.trim="userData.partner.director"
                  :counter="99"
                  @input="$v.userData.partner.director.$touch()"
                  @blur="$v.userData.partner.director.$touch()"
                  :error-messages="directorErrors"
                  label="ФИО представителя"
                ></v-text-field>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model.trim="userData.partner.inn"
                      :counter="12"
                      @input="$v.userData.partner.inn.$touch()"
                      @blur="$v.userData.partner.inn.$touch()"
                      :error-messages="innErrors"
                      label="ИНН"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model.trim="userData.partner.kpp"
                      :counter="9"
                      @input="$v.userData.partner.kpp.$touch()"
                      @blur="$v.userData.partner.kpp.$touch()"
                      :error-messages="kppErrors"
                      label="КПП"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  v-model.trim="userData.partner.ogrn"
                  :counter="15"
                  @input="$v.userData.partner.ogrn.$touch()"
                  @blur="$v.userData.partner.ogrn.$touch()"
                  :error-messages="ogrnErrors"
                  label="ОГРН / ОГРНИП"
                ></v-text-field>
                <v-text-field
                  v-model.trim="userData.partner.creationDate"
                  :counter="10"
                  @input="$v.userData.partner.creationDate.$touch()"
                  @blur="$v.userData.partner.creationDate.$touch()"
                  :error-messages="creationDateErrors"
                  label="Дата присвоения ОГРН"
                ></v-text-field>
                <v-text-field
                  v-model.trim="userData.partner.bank"
                  :counter="199"
                  @input="$v.userData.partner.bank.$touch()"
                  @blur="$v.userData.partner.bank.$touch()"
                  :error-messages="bankErrors"
                  label="Банковские реквизиты (р/с, банк, к/с, БИК банка)"
                ></v-text-field>
              </div>
              <div v-if="userData.partner.orgType === 1">
                <v-text-field
                  v-model.trim="userData.partner.name"
                  :counter="99"
                  @input="$v.userData.partner.name.$touch()"
                  @blur="$v.userData.partner.name.$touch()"
                  :error-messages="nameErrors"
                  label="ФИО"
                ></v-text-field>
                <v-text-field
                  v-model.trim="userData.partner.snils"
                  :counter="11"
                  @input="$v.userData.partner.snils.$touch()"
                  @blur="$v.userData.partner.snils.$touch()"
                  :error-messages="snilsErrors"
                  hint="Без проблеов и дефисов"
                  label="СНИЛС"
                ></v-text-field>
                <v-text-field
                  v-model.trim="userData.partner.passportNum"
                  :counter="10"
                  @input="$v.userData.partner.passportNum.$touch()"
                  @blur="$v.userData.partner.passportNum.$touch()"
                  :error-messages="passportNumErrors"
                  label="Паспорт серия и №"
                ></v-text-field>
                <v-text-field
                  v-model.trim="userData.partner.passportOrg"
                  :counter="199"
                  @input="$v.userData.partner.passportOrg.$touch()"
                  @blur="$v.userData.partner.passportOrg.$touch()"
                  :error-messages="passportOrgErrors"
                  label="Кем и когда выдан"
                ></v-text-field>
                <v-text-field
                  v-model.trim="userData.partner.inn"
                  :counter="12"
                  @input="$v.userData.partner.inn.$touch()"
                  @blur="$v.userData.partner.inn.$touch()"
                  :error-messages="innErrors"
                  label="ИНН"
                ></v-text-field>
              </div>
              <v-text-field
                v-model.trim="userData.partner.address"
                :counter="199"
                @input="$v.userData.partner.address.$touch()"
                @blur="$v.userData.partner.address.$touch()"
                :error-messages="addressErrors"
                label="Адрес"
              ></v-text-field>
              <v-text-field
                v-model.trim="userData.partner.telephone"
                :counter="11"
                @input="$v.userData.partner.telephone.$touch()"
                @blur="$v.userData.partner.telephone.$touch()"
                :error-messages="telephoneErrors"
                label="Телефон"
              ></v-text-field>
              <v-text-field
                v-model.trim="userData.partner.email"
                :counter="99"
                @input="$v.userData.partner.email.$touch()"
                @blur="$v.userData.partner.email.$touch()"
                :error-messages="emailErrors"
                label="e-Mail"
              ></v-text-field>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              outlined
              color="indigo"
              :loading="loadingPartner"
              :disabled="loadingPartner"
              @click.prevent="updatePartner()"
              >Обновить данные потребителя</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { sendRequest } from '../utils/graphql';
import { mapMutations } from 'vuex';
import { DICTIONARY_TAG, VALID_ERRORS } from '../utils/mainConsts';
import {
  between,
  required,
  minLength,
  maxLength,
  numeric,
  helpers,
  email
} from 'vuelidate/lib/validators';

// Проверки даты на формат ДД.ММ.ГГГГ через регулярное выражение
// const dateMatch = /^((((0[13578])|([13578])|(1[02]))[.](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[.](([1-9])|([0-2][0-9])|(30)))|((2|02)[.](([1-9])|([0-2][0-9]))))[.]\d{4}$|^\d{4}$/;

// const confirmData = value => {
//   return dateMatch.test(value);
// };

const checkValid = (obj, ...fields) => {
  let valid = true;
  for (const f of fields) {
    if (obj[f].$invalid) {
      valid = false;
      break;
    }
  }
  return valid;
};

export default {
  name: 'Profile',
  data: () => ({
    loadingPartner: false,
    userData: {
      name: '',
      email: '',
      partner: {
        _id: 0,
        name: ''
      }
    }
  }),
  validations: {
    userData: {
      partner: {
        orgType: {
          between: between(0, 1)
        },
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(99)
        },
        shortName: {
          maxLength: maxLength(30)
        },
        director: {
          maxLength: maxLength(99)
        },
        inn: {
          required,
          maxLength: maxLength(12),
          numeric: numeric
        },
        kpp: {
          maxLength: maxLength(9),
          numeric: numeric
        },
        ogrn: {
          required,
          maxLength: maxLength(15),
          numeric: numeric
        },
        creationDate: {
          maxLength: maxLength(10)
          // confirmData
        },
        bank: {
          maxLength: maxLength(199)
        },
        snils: {
          required,
          maxLength: maxLength(11),
          numeric: numeric
        },
        passportNum: {
          required,
          maxLength: maxLength(10),
          numeric: numeric
        },
        passportOrg: {
          required,
          maxLength: maxLength(199)
        },
        address: {
          required,
          maxLength: maxLength(199)
        },
        telephone: {
          required,
          maxLength: maxLength(11),
          numeric: numeric
        },
        email: {
          required,
          maxLength: maxLength(99),
          email
        }
      }
    }
  },

  computed: {
    orgTypeErrors() {
      const errors = [];
      if (!this.$v.userData.partner.orgType.$dirty) return errors;
      !this.$v.userData.partner.orgType.between &&
        errors.push(VALID_ERRORS.orgType);
      return errors;
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.userData.partner.name.$dirty) return errors;
      !this.$v.userData.partner.name.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.userData.partner.name.minLength &&
        errors.push(VALID_ERRORS.minLength(3));
      !this.$v.userData.partner.name.maxLength &&
        errors.push(VALID_ERRORS.maxLength(99));
      return errors;
    },

    shortNameErrors() {
      const errors = [];
      if (!this.$v.userData.partner.shortName.$dirty) return errors;
      !this.$v.userData.partner.shortName.maxLength &&
        errors.push(VALID_ERRORS.maxLength(30));
      return errors;
    },

    directorErrors() {
      const errors = [];
      if (!this.$v.userData.partner.director.$dirty) return errors;
      !this.$v.userData.partner.director.maxLength &&
        errors.push(VALID_ERRORS.maxLength(99));
      return errors;
    },

    innErrors() {
      const errors = [];
      if (!this.$v.userData.partner.inn.$dirty) return errors;
      !this.$v.userData.partner.inn.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.userData.partner.inn.maxLength &&
        errors.push(VALID_ERRORS.maxLength(12));
      !this.$v.userData.partner.inn.numeric &&
        errors.push(VALID_ERRORS.numeric);
      return errors;
    },

    kppErrors() {
      const errors = [];
      if (!this.$v.userData.partner.kpp.$dirty) return errors;
      !this.$v.userData.partner.kpp.maxLength &&
        errors.push(VALID_ERRORS.maxLength(9));
      !this.$v.userData.partner.kpp.numeric &&
        errors.push(VALID_ERRORS.numeric);
      return errors;
    },

    ogrnErrors() {
      const errors = [];
      if (!this.$v.userData.partner.ogrn.$dirty) return errors;
      !this.$v.userData.partner.ogrn.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.userData.partner.ogrn.maxLength &&
        errors.push(VALID_ERRORS.maxLength(15));
      !this.$v.userData.partner.ogrn.numeric &&
        errors.push(VALID_ERRORS.numeric);
      return errors;
    },

    creationDateErrors() {
      const errors = [];
      if (!this.$v.userData.partner.creationDate.$dirty) return errors;
      !this.$v.userData.partner.creationDate.maxLength &&
        errors.push(VALID_ERRORS.maxLength(10));
      // !this.$v.userData.partner.creationDate.confirmData &&
      //   errors.push(VALID_ERRORS.dateMatch);
      return errors;
    },

    bankErrors() {
      const errors = [];
      if (!this.$v.userData.partner.bank.$dirty) return errors;
      !this.$v.userData.partner.bank.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    },

    snilsErrors() {
      const errors = [];
      if (!this.$v.userData.partner.snils.$dirty) return errors;
      !this.$v.userData.partner.snils.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.userData.partner.snils.maxLength &&
        errors.push(VALID_ERRORS.maxLength(11));
      !this.$v.userData.partner.snils.numeric &&
        errors.push(VALID_ERRORS.numeric);
      return errors;
    },

    passportNumErrors() {
      const errors = [];
      if (!this.$v.userData.partner.passportNum.$dirty) return errors;
      !this.$v.userData.partner.passportNum.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.userData.partner.passportNum.maxLength &&
        errors.push(VALID_ERRORS.maxLength(10));
      !this.$v.userData.partner.passportNum.numeric &&
        errors.push(VALID_ERRORS.numeric);
      return errors;
    },

    passportOrgErrors() {
      const errors = [];
      if (!this.$v.userData.partner.passportOrg.$dirty) return errors;
      !this.$v.userData.partner.passportOrg.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.userData.partner.passportOrg.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    },

    telephoneErrors() {
      const errors = [];
      if (!this.$v.userData.partner.telephone.$dirty) return errors;
      !this.$v.userData.partner.telephone.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.userData.partner.telephone.maxLength &&
        errors.push(VALID_ERRORS.maxLength(11));
      !this.$v.userData.partner.telephone.numeric &&
        errors.push(VALID_ERRORS.numeric);
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.userData.partner.email.$dirty) return errors;
      !this.$v.userData.partner.email.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.userData.partner.email.email && errors.push(VALID_ERRORS.email);
      !this.$v.userData.partner.email.maxLength &&
        errors.push(VALID_ERRORS.maxLength(99));
      return errors;
    },

    addressErrors() {
      const errors = [];
      if (!this.$v.userData.partner.address.$dirty) return errors;
      !this.$v.userData.partner.address.required &&
        errors.push(VALID_ERRORS.required);
      !this.$v.userData.partner.address.maxLength &&
        errors.push(VALID_ERRORS.maxLength(199));
      return errors;
    }
  },

  async mounted() {
    // Загрузить все данные профиля этого пользователя
    const id = this.$store.state.userId;
    const result = await this.$store.dispatch('uniFetch', {
      name: DICTIONARY_TAG.user,
      id
    });

    this.userData = {
      name: result.name,
      email: result.email,
      partner: {
        ...result.partner
      }
    };
  },

  methods: {
    ...mapMutations(['setMessageData']),
    async updateUser() {
      const token = this.$store.state.token;
      const userId = this.$store.state.userId;
      const query = `mutation UserEdit($id: String!, $userInput: UserInput!) {
        userEdit(id: $id, itemInput: $userInput) {
          _id
        }
      }`;
      const variables = {
        id: userId,
        userInput: {
          ...this.userData
        }
      };
      delete variables.userInput.name;
      delete variables.userInput.partner; // инфу о партнере не трогаем
      const result = await sendRequest(token, query, variables);
      // Сообщить о результате
      let message = 'Данные пользователя успешно обновлены';
      if (result.error) {
        message = `Ошибка: ${result.error}`;
      }
      this.setMessageData({
        message,
        type: 'snackbar'
      });
    },
    async updatePassword() {
      console.log('updatePassword');
    },
    async updatePartner() {
      this.$v.$touch();

      // Это валидация формы.
      if (
        (+this.userData.partner.orgType === 1 &&
          checkValid(
            this.$v.userData.partner,
            'name',
            'snils',
            'passportNum',
            'passportOrg',
            'inn',
            'address',
            'telephone',
            'email'
          )) ||
        (+this.userData.partner.orgType === 0 &&
          checkValid(
            this.$v.userData.partner,
            'name',
            'shortName',
            'director',
            'kpp',
            'inn',
            'ogrn',
            'creationDate',
            'bank',
            'address',
            'telephone',
            'email'
          ))
      ) {
        const token = this.$store.state.token;
        const createdBy = this.$store.state.userId;
        const query = `mutation UpdatePartner($userId: String!, $partnerInput: PartnerInput!) {
          partnerUpdate(userId: $userId, partnerInput: $partnerInput) {
            _id
          }
        }`;
        const variables = {
          partnerInput: {
            ...this.userData.partner,
            createdBy
          },
          userId: createdBy
        };
        delete variables.partnerInput._id;

        this.loadingPartner = true;

        await sendRequest(token, query, variables, result => {
          this.loadingPartner = false;
          // Сообщить о результате
          let message = 'Данные клиента успешно обновлены';
          if (result.errors) {
            const errorText = result.errors[0].data.reduce((summ, curr) => {
              return summ + curr.message + ', ';
            }, '');
            message = `Ошибка: ${errorText}`;
          }
          this.setMessageData({
            message,
            type: 'snackbar'
          });
        });
      } else {
        console.log('Отказ валидации', this.$v);
        this.setMessageData({
          message: 'Форма заполнена с ошибкой!',
          type: 'snackbar'
        });
      }
    }
  }
};
</script>
